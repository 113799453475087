import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  showMenu(_event) {
    this.menuTarget.removeAttribute('hidden');
  }

  hideMenu(_event) {
    this.menuTarget.setAttribute('hidden', '');
  }
}
