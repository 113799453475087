import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['section', 'collapseIcon', 'expandIcon'];

  initialize() {
    this.name = this.element.dataset.name;

    if (window.localStorage.getItem(this.name) === 'true') {
      this.toggleHidden();
    } else {
      window.localStorage.setItem(this.name, 'false');
    }
  }

  toggleCollapsibleSection(_event) {
    const isOpen = (window.localStorage.getItem(this.name) === 'false');

    if (isOpen === true) {
      window.localStorage.setItem(this.name, 'true');
    } else {
      window.localStorage.setItem(this.name, 'false');
    }

    this.toggleHidden();
  }

  toggleHidden() {
    this.sectionTarget.toggleAttribute('hidden');
    this.collapseIconTarget.toggleAttribute('hidden');
    this.expandIconTarget.toggleAttribute('hidden');
  }
}
