import { Controller } from '@hotwired/stimulus';

function createHiddenInput(name, value) {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value;
  return input;
}

export default class extends Controller {
  static values = {
    editing: Boolean,
    count: Number,
  };

  static targets = [
    'activeStateOptions', 'inactiveStateOptions', 'editButton', 'updateBulkEditProjectsListForm', 'updateForm',
  ];

  static outlets = [
    'drawer',
  ];

  initialize() {
    this.selectedItems = new Map();
  }

  activeStateOptionsTargetConnected(target) {
    // TODO: Don't want the animation to occur if just expanding/closing a team member, but modifying CSS here feels
    // wrong
    if (this.editingValue === true) {
      target.classList.replace('opacity-0', 'opacity-100');
      target.classList.remove('translate-x-full');
      target.removeAttribute('hidden');
      this.countValueChanged(this.selectedItems.size);
    }
  }

  inactiveStateOptionsTargetConnected(target) {
    // TODO: Don't want the animation to occur if just expanding/closing a team member, but modifying CSS here feels
    // wrong
    if (this.editingValue === true) {
      target.classList.add('opacity-0');
      target.setAttribute('hidden', '');
    }
  }

  startEdit() {
    this.editingValue = true;
  }

  stopEdit() {
    this.editingValue = false;
  }

  update({
    detail: {
      selected, itemId, ...item
    },
  }) {
    if (selected) {
      this.selectedItems.set(itemId, item);
      this.#addFromAssignment(itemId);
    } else {
      this.selectedItems.delete(itemId);
      this.#removeFromAssignment(itemId);
    }

    if (this.hasDrawerOutlet) {
      this.drawerOutlet.close();
    }
    this.updateCount();
  }

  deselectAll() {
    this.selectedItems.forEach((_, itemId) => {
      this.dispatch('deselectItem', { detail: { itemId } });
    });
  }

  updateCount() {
    this.countValue = this.selectedItems.size;
  }

  countValueChanged(count) {
    this.updateEditText(count);

    if (count > 0) {
      this.editButtonTarget.disabled = false;
    } else {
      this.editButtonTarget.disabled = true;
    }
  }

  updateEditText(count) {
    this.editButtonTarget.firstElementChild.textContent = `Edit (${count})`;
  }

  editingValueChanged(value) {
    if (value) {
      this.showActiveStateOptions();
    } else {
      this.showInactiveStateOptions();
      this.deselectAll();
      this.selectedItems.clear();
      this.updateCount();
    }
  }

  showActiveStateOptions() {
    this.activeStateOptionsTarget.removeAttribute('hidden');

    requestAnimationFrame(() => {
      this.activeStateOptionsTarget.classList.remove('translate-x-full', 'opacity-0');
      this.activeStateOptionsTarget.classList.add('translate-x-0', 'opacity-100');
    });

    this.inactiveStateOptionsTarget.classList.remove('translate-x-0', 'opacity-100');
    this.inactiveStateOptionsTarget.classList.add('translate-x-full', 'opacity-0');

    setTimeout(() => {
      this.inactiveStateOptionsTarget.setAttribute('hidden', '');
    }, 300);
  }

  showInactiveStateOptions() {
    this.activeStateOptionsTarget.classList.remove('translate-x-0', 'opacity-100');
    this.activeStateOptionsTarget.classList.add('translate-x-full', 'opacity-0');

    setTimeout(() => {
      this.activeStateOptionsTarget.setAttribute('hidden', '');

      this.inactiveStateOptionsTarget.removeAttribute('hidden');

      requestAnimationFrame(() => {
        this.inactiveStateOptionsTarget.classList.remove('translate-x-full', 'opacity-0');
        this.inactiveStateOptionsTarget.classList.add('translate-x-0', 'opacity-100');
      });
    }, 300);
  }

  updateBulkEditProjectsList() {
    this.updateBulkEditProjectsListFormTarget
      .querySelectorAll('input[name="project_card_ids[]"]')
      .forEach((input) => input.remove());

    this.selectedItems.forEach((_, itemId) => {
      this.updateBulkEditProjectsListFormTarget.appendChild(createHiddenInput('project_card_ids[]', itemId));
    });

    this.updateBulkEditProjectsListFormTarget.requestSubmit();
  }

  #addFromAssignment(itemId) {
    const {
      assigneeId, year, week, projectId,
    } = this.selectedItems.get(itemId);

    this.#addFromAssignmentInput(itemId, 'assignee_id', assigneeId);
    this.#addFromAssignmentInput(itemId, 'year', year);
    this.#addFromAssignmentInput(itemId, 'week', week);
    this.#addFromAssignmentInput(itemId, 'project_id', projectId);
  }

  #addFromAssignmentInput(itemId, name, value) {
    const input = createHiddenInput(`from_assignments[][${name}]`, value);
    input.dataset.itemId = itemId;
    this.updateFormTarget.appendChild(input);
  }

  #removeFromAssignment(itemId) {
    this.updateFormTarget
      .querySelectorAll(`input[data-item-id="${itemId}"]`)
      .forEach((input) => input.remove());
  }
}
